import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader } from "../utils"
import gamesImg from "../images/BGgamepads28.png"
import Gallery from "../components/HomePageComponents/Gallery"

class GamesPage extends Component {
  constructor(props) {
    super(props)
    this.state = { textExpanded: false }
  }
  handleClick = () => {
    this.setState({ textExpanded: !this.state.textExpanded })

    console.log("test", this.state.textExpanded)
  }

  render() {
    return (
      <Layout>
        <SEO title="Games" />
        <PageHeader img={gamesImg}>
          <Gallery />
        </PageHeader>
      </Layout>
    )
  }
}

export default GamesPage
