import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { styles, Section } from "../../utils"
import Img from "gatsby-image"
import { Link } from "gatsby"

const SINGLE_IMAGE = graphql`
  {
    img1: file(relativePath: { eq: "choppy-orc.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "greedy-mimic.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "hasty-shaman.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "sleepy-knight.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "jelly-bots.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "one-trick-mage.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img7: file(relativePath: { eq: "orcs-vs-elves.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img8: file(relativePath: { eq: "sticky-sorcerer.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img9: file(relativePath: { eq: "dozen-bear.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Gallery() {
  const data = useStaticQuery(SINGLE_IMAGE)
  console.log(data)

  const img1 = data.img1.childImageSharp.fluid
  const img2 = data.img2.childImageSharp.fluid
  const img3 = data.img3.childImageSharp.fluid
  const img4 = data.img4.childImageSharp.fluid
  const img5 = data.img5.childImageSharp.fluid
  const img6 = data.img6.childImageSharp.fluid
  const img7 = data.img7.childImageSharp.fluid
  const img8 = data.img8.childImageSharp.fluid
  const img9 = data.img9.childImageSharp.fluid

  return (
    <Section>
      <GalleryWrapper>
        <div className="item item-1">
          <Link to="/games/choppy-orc">
            <Img fluid={img1} />
            <p className="info">Choppy Orc</p>
          </Link>
        </div>
        <div className="item item-2">
          <Link to="/games/greedy-mimic">
            <Img fluid={img2} />
            <p className="info">Greedy Mimic</p>
          </Link>
        </div>

        <div className="item item-3">
          <Link to="/games/hasty-shaman">
            <Img fluid={img3} />
            <p className="info">Hasty Shaman</p>
          </Link>
        </div>
        <div className="item item-4">
          <Link to="/games/sleepy-knight">
            <Img fluid={img4} />
            <p className="info">Sleepy Knight</p>
          </Link>
        </div>
        <div className="item item-5">
          <Link to="/games/jelly-bots">
            <Img fluid={img5} />
            <p className="info">jellybots</p>
          </Link>
        </div>
        <div className="item item-6">
          <Link to="/games/one-trick-mage">
            <Img fluid={img6} />
            <p className="info">One Trick Mage</p>
          </Link>
        </div>
        <div className="item item-7">
          <Link to="/games/orcs-vs-elves">
            <Img fluid={img7} />
            <p className="info">Orcs VS Elves</p>
          </Link>
        </div>
        <div className="item item-8">
          <Link to="/games/sticky-sorcerer">
            <Img fluid={img8} />
            <p className="info">Sticky Sorcerer</p>
          </Link>
        </div>
        <div className="item item-9">
          <Link to="/games/dozenbear">
            <Img fluid={img9} />
            <p className="info">Dozenbear</p>
          </Link>
        </div>
      </GalleryWrapper>
    </Section>
  )
}

const GalleryWrapper = styled.div`
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 30px;

  //grid-template-columns: auto;
  //grid-row-gap: 2rem;

  .item {
    position: relative;

    border: 2px solid white;
  }
  .info {
    position: absolute;
    top: 0;
    color: ${styles.colors.mainWhite};
    background: ${styles.colors.mainBlack};
    padding: 0.1rem 0.3rem;
    text-transform: capitalize;
  }
  /* @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 992px) {
    grid-template-areas:
      "one one one one"
      "one one three three"; */
  //}
`
